<template>
  <ProjectGet
    v-if="user"
    :id="projectId"
    v-slot="{ project, isGetPending }"
  >
    <WithSidebar v-if="!isGetPending && project">
      <template #sidebar-header>
        <ProjectNavPortalHeader
          :organization="organization"
          :project="project"
        />
      </template>
      <template #sidebar>
        <div class="flex flex-col h-full">
          <div class="flex-1 overflow-y-auto">
            <ProjectNav
              :organization="organization"
              :project="project"
              :docs="docs"
              :routes="navRoutes"
            />
          </div>
          <FeatureFlags
            v-slot="{ fullWhiteLabel }"
            :flags="['fullWhiteLabel']"
          >
            <PoweredBySuperokay v-if="!fullWhiteLabel" />
          </FeatureFlags>
        </div>
      </template>
      <template #default>
        <Provide :provide="{ project }">
          <Watch
            :value="project?._id"
            immediate
            @change="onProjectChanged(project)"
          >
            <LoaderWebFont
              :families="[
                project?.brandingFontHeading ?? 'Inter',
                project?.brandingFontParagraph ?? 'Inter',
              ]"
            >
              <ProjectTheme :project="project">
                <router-view
                  v-if="!isLocalePending"
                  :user="user"
                  :organization="organization"
                  :client="client"
                  :project="project"
                  :preview="preview"
                />
              </ProjectTheme>
            </LoaderWebFont>
          </Watch>
        </Provide>
      </template>
    </WithSidebar>
  </ProjectGet>
</template>
<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { useNamespacedActions, useNamespacedMutations } from 'vuex-composition-helpers'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import { injectElementsInContainer } from '@/helpers/dom'
import { documents, actionItems } from '@/v2/services'
import { useLocalStorageRef } from '@/v2/lib/composition/useLocalStorage'
import useLocalization from '@/v2/lib/composition/useLocalization'
import useTreeProjectsFolders from '@/v2/lib/composition/useTreeProjectFolders';
import {
  PORTAL_DASHBOARD,
  PORTAL_TASKS,
  PORTAL_LINKED_APP,
  PORTAL_PAGE,
  PORTAL_UPDATES,
  PORTAL_FS_FOLDER,
  PORTAL_PACKAGED_SERVICES_DOCUMENT,
} from '@/client-portal/router/types';
import Provide from '@/components/Provide.vue'
import ProjectGet from '@/components/ProjectGet.vue'
import PoweredBySuperokay from '@/components/PoweredBySuperokay.vue'
import FeatureFlags from '@/components/FeatureFlags.vue'
import Watch from '@/components/Watch.vue'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';
import ProjectNav from '@/components/ProjectNav.vue'
import WithSidebar from '@/components/WithSidebar.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import ProjectNavPortalHeader from '@/components/ProjectNavPortalHeader.vue'

const navRoutes = {
  projectDashboard: PORTAL_DASHBOARD,
  projectLinkedApp: PORTAL_LINKED_APP,
  projectUpdates: PORTAL_UPDATES,
  // projectNotifications: PORTAL_NOTIFICATIONS, // not implemented yet
  projectPage: PORTAL_PAGE,
  projectService: PORTAL_PACKAGED_SERVICES_DOCUMENT,
  projectFsFolder: PORTAL_FS_FOLDER,
  projectTasks: PORTAL_TASKS,
}

export default defineComponent({
  name: 'ViewClientPortalProject',
  components: {
    WithSidebar,
    Provide,
    ProjectGet,
    PoweredBySuperokay,
    FeatureFlags,
    Watch,
    LoaderWebFont,
    ProjectNav,
    ProjectTheme,
    ProjectNavPortalHeader,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    projectId: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { setLocale } = useLocalization()
    const isLocalePending = ref(false)
    const keyword = ref('')

    const { purgeFromStore: purgeDocumentFromStore } = useNamespacedActions(
      'documents',
      ['purgeFromStore']
    )

    const { removeItem: removeActionItem } = useNamespacedMutations(
      'action-items',
      ['removeItem']
    )

    const setProjectLocale = async project => {
      const { preferences } = props.user
      const locale = preferences?.locale ?? project.locale ?? import.meta.VITE_I18N_LOCALE
      await setLocale(locale)
    }

    documents.on('unpublished', purgeDocumentFromStore)
    actionItems.on('unpublished', removeActionItem)

    onUnmounted(() => {
      documents.off('unpublished', purgeDocumentFromStore)
      actionItems.off('unpublished', removeActionItem)
    })

    /** Loads project integrations into the Client Portal by mounting custom code into the DOM */
    const loadProjectIntegrations = project => {
      if (!project?.modules.integrations.enabledPortal
        || !project?.portalIntegrations?.length) {
        return
      }

      /** Reference to <div> containing custom scripts */
      const scriptContainer = document.getElementById('custom-portal-integrations')
      // Clear any previously loaded custom scripts belonging to previous project
      // NOTE: A page reload is required when project is switched in order to clear any elements
      // injected in the DOM by custom scripts. Because of this, `scriptContainer` is already
      // empty
      // scriptContainer.replaceChildren() // Clear container content
      // Load custom project scripts
      project.portalIntegrations.forEach(integration => {
        injectElementsInContainer(integration.customCode.trim(), scriptContainer)
      })
    }

    const organizationId = computed(() => props.organization._id)
    const projectId = computed(() => props.projectId)

    const docs = useTreeProjectsFolders({
      projectId,
      folderCategory: CATEGORY.ProjectPublicFolder,
    })

    const lastProjectId = useLocalStorageRef('clientPortalLastProjectId')

    const onProjectChanged = async project => {
      if (project) {
        isLocalePending.value = true
        await setProjectLocale(project)
        isLocalePending.value = false
        lastProjectId.value = project._id

        loadProjectIntegrations(project)
      }
    }

    return {
      // setLastProjectId,
      isLocalePending,
      onProjectChanged,
      docs,
      keyword,
      setProjectLocale,
      navRoutes,
    }
  },
})
</script>
