
<ProjectGet
  v-if="user"
  :id="projectId"
  v-slot="{ project, isGetPending }"
>
  <WithSidebar v-if="!isGetPending && project">
    <template #sidebar-header>
      <ProjectNavPortalHeader
        :organization="organization"
        :project="project"
      />
    </template>
    <template #sidebar>
      <div class="flex flex-col h-full">
        <div class="flex-1 overflow-y-auto">
          <ProjectNav
            :organization="organization"
            :project="project"
            :docs="docs"
            :routes="navRoutes"
          />
        </div>
        <FeatureFlags
          v-slot="{ fullWhiteLabel }"
          :flags="['fullWhiteLabel']"
        >
          <PoweredBySuperokay v-if="!fullWhiteLabel" />
        </FeatureFlags>
      </div>
    </template>
    <template #default>
      <Provide :provide="{ project }">
        <Watch
          :value="project?._id"
          immediate
          @change="onProjectChanged(project)"
        >
          <LoaderWebFont
            :families="[
              project?.brandingFontHeading ?? 'Inter',
              project?.brandingFontParagraph ?? 'Inter',
            ]"
          >
            <ProjectTheme :project="project">
              <router-view
                v-if="!isLocalePending"
                :user="user"
                :organization="organization"
                :client="client"
                :project="project"
                :preview="preview"
              />
            </ProjectTheme>
          </LoaderWebFont>
        </Watch>
      </Provide>
    </template>
  </WithSidebar>
</ProjectGet>
